import axios from "axios";

const config = {
  baseURL:
    process.env.NODE_ENV === "development" ? "" : process.env.VUE_APP_API_URL,
  headers: {
    "Content-type": "application/json; charset=UTF-8",
    "X-Requested-With": "XMLHttpRequest",
  },
};

const axiosClient = axios.create(config);

axiosClient.interceptors.request.use((cfg) => {
  return cfg;
}, undefined);

axiosClient.interceptors.response.use(undefined, async (error) => {
  console.error(error);
  return error.response;
});

export default axiosClient;
