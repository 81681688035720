import { WrapperGetters } from "./types";

const getters: WrapperGetters = {
  getIsNavLocked: (state) => state.lockNav,
  getIsScrollLocked: (state) => state.lockNav,
  getIsPageLoading: (state) => state.pageLoading,
  getIsPageLoadingOut: (state) => state.pageLoadingOut,
  getIsNavToggled: (state) => state.toggledNav,
  getIsCookiesConsentVisible: (state) => state.cookiesConsent,
};

export default getters;
