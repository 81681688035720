const cdnPath =
  "https://firebasestorage.googleapis.com/v0/b/annawojcieszekpl.appspot.com";
const cdnDefaultBucket = "o";

export const getCdnImageUrl = (
  path: string,
  bucket = cdnDefaultBucket,
): string => {
  return `${cdnPath}/${bucket}/${path}?alt=media`;
};
