import state from "./state";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import { ShopModule } from "./types";

const shop: ShopModule = {
  state,
  getters,
  mutations,
  actions,
};

export default shop;
