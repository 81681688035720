import state from "./state";
// import actions from './actions'
import mutations from "./mutations";
import getters from "./getters";
import { WrapperModule } from "./types";

const wrapper: WrapperModule = {
  state,
  getters,
  mutations,
  // actions,
};

export default wrapper;
