import { ShopServiceType } from "./types";
import endpoints from "./endpoints";
import Service, { formatResponseError } from "@/api";
import { HttpStatusCode, Return } from "@/api/types";
import {
  Basket,
  OrderConfirmation,
  OrderDetails,
  OrderPayload,
  OrderPayloadError,
  ProductList,
} from "../../../../../typings/shop";
import { CreatedIdResponse, CreatedOrNullIdResponse } from "#/leave-phone";

class ShopService extends Service implements ShopServiceType {
  async getProductsList(): Return<ProductList> {
    const res = await this.client.get(endpoints.getProductsList());

    if (res.status === HttpStatusCode.OK) {
      return res.data as ProductList;
    }

    throw new Error(formatResponseError(res));
  }

  async getBasket(basketId: string): Return<Basket> {
    const res = await this.client.get(endpoints.getBasket(basketId));

    if (res.status === HttpStatusCode.OK) {
      return res.data as Basket;
    }

    throw new Error(formatResponseError(res));
  }

  async addToBasket(
    productId: string,
    basketId?: string,
    quantity?: number,
  ): Return<CreatedIdResponse> {
    const res = await this.client.post(endpoints.addToBasket(), {
      productId,
      basketId,
      quantity,
    });

    if (res.status === HttpStatusCode.OK) {
      return res.data;
    }

    throw new Error(formatResponseError(res));
  }

  async removeFromBasket(
    productId: string,
    basketId: string,
  ): Return<CreatedOrNullIdResponse> {
    const res = await this.client.put(endpoints.removeFromBasket(), {
      productId,
      basketId,
    });

    if (res.status === HttpStatusCode.OK) {
      return res.data;
    }

    throw new Error(formatResponseError(res));
  }

  async makeOrder(payload: OrderPayload): Return<OrderConfirmation> {
    const res = await this.client.post(endpoints.makeOrder(), payload);

    if (res.status === HttpStatusCode.OK) {
      return res.data as OrderConfirmation;
    }

    if (typeof res.data === "object" && res.data.orderPayloadError) {
      throw new OrderPayloadError(res.data);
    }

    throw new Error(formatResponseError(res));
  }

  async getOrder(orderId: string, orderToken: string): Return<OrderDetails> {
    const res = await this.client.get(endpoints.getOrder(orderId, orderToken));

    if (res.status === HttpStatusCode.OK) {
      return res.data as OrderDetails;
    }

    throw new Error(formatResponseError(res));
  }
}

export const shopService = new ShopService();
