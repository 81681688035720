import { WrapperState } from "./types";

const state: WrapperState = {
  lockNav: false,
  lockScroll: false,
  toggledNav: false,
  pageLoading: {
    toggle: true,
    transparent: undefined,
  },
  pageLoadingOut: false,
  cookiesConsent: false,
};

export default state;
