<template>
  <label :for="elementId" :class="error ? '--error' : ''" class="checkbox">
    <span v-if="error" class="-error">{{ error }}</span>
    <span>
      <input
        :id="elementId"
        :value="modelValue"
        :required="required"
        :name="name"
        type="checkbox"
        @change="$emit('update:modelValue', $event.target.checked)"
      />
    </span>
    <span><span v-if="required" class="-required">* </span>{{ label }}</span>
  </label>
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance } from "vue";

export default defineComponent({
  name: "CheckboxComponent",

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: undefined,
    },
    error: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const elementId = computed(() => props.id || getCurrentInstance()?.uid);

    return {
      elementId,
    };
  },
});
</script>

<style lang="scss">
.checkbox {
  cursor: pointer;
  display: block;
  width: calc(100% - 10px);
  padding: 8px 5px;
  background-color: rgba(var(--c-secondary), 0.2);
  border-radius: 5px;

  &.--error {
    background-color: rgba(red, 0.2);
  }

  .-error {
    display: block;
    color: red;
  }

  & + label {
    margin-top: 10px;
  }

  &.--flat {
    width: auto;
    display: inline-block;
    margin-right: 10px;
    padding-right: 10px;

    & + label {
      margin-top: 0px;
    }
  }
}
</style>
