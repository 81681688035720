import { FieldErrors, FormError, HomeServiceType } from "./types";
import endpoints from "./endpoints";
import Service, { formatResponseError } from "@/api";
import { HttpStatusCode, Return } from "@/api/types";
import { ClassResponse } from "#/classes";
import { CustomerReviewResponse } from "#/customer-reviews";
import {
  LeavePhonePayload,
  CreatedIdResponse,
  ContactFormPayload,
} from "#/leave-phone";
import { Pages } from "#/pages";

class HomeService extends Service implements HomeServiceType {
  async getClasses(): Return<ClassResponse> {
    const res = await this.client.get(endpoints.getClasses());

    if (res.status === HttpStatusCode.OK) {
      return res.data as ClassResponse;
    }

    throw new Error(formatResponseError(res));
  }

  async getPage(page: string): Return<Pages> {
    const res = await this.client.get(endpoints.getPage(page));

    if (res.status === HttpStatusCode.OK) {
      return res.data as Pages;
    }

    throw new Error(formatResponseError(res));
  }

  async getClosestClasses(): Return<ClassResponse> {
    const res = await this.client.get(endpoints.getClosestClasses());

    if (res.status === HttpStatusCode.OK) {
      return res.data as ClassResponse;
    }

    throw new Error(formatResponseError(res));
  }

  async getCustomerReviews(): Return<CustomerReviewResponse> {
    const res = await this.client.get(endpoints.getCustomerReviews());

    if (res.status === HttpStatusCode.OK) {
      return res.data as CustomerReviewResponse;
    }

    throw new Error(formatResponseError(res));
  }

  async getHomeInstagramPost(): Return<{ permalink: string }> {
    const res = await this.client.get(endpoints.getHomeInstagramPost());

    if (res.status === HttpStatusCode.OK) {
      return res.data;
    }

    throw new Error(formatResponseError(res));
  }

  async postLeavePhone(data: LeavePhonePayload): Return<CreatedIdResponse> {
    const res = await this.client.post(endpoints.postLeavePhone(), data);

    if (res.status === HttpStatusCode.OK) {
      return res.data as CreatedIdResponse;
    }

    throw new Error(formatResponseError(res));
  }

  async postContactForm(data: ContactFormPayload): Return<CreatedIdResponse> {
    const errorFields: FieldErrors<ContactFormPayload> = {};
    const errorMessage: string[] = [];

    if (data.firstname.length < 3) {
      errorFields.firstname = true;
      errorMessage.push("Imię powinno składać się z co najmniej 3 znaków.");
    }

    const emailRegex = new RegExp(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
    );

    if (!emailRegex.test(data.email)) {
      errorFields.email = true;
      errorMessage.push("Podany adres e-mail nie jest prawidłowy.");
    }

    if (data.content.length < 3) {
      errorFields.content = true;
      errorMessage.push("Wiadomość powinna składać się z co najmniej znaków.");
    }

    if (!data.legal) {
      errorFields.legal = true;
      errorMessage.push("Nie wyrażono zgody na przetwarzanie danych.");
    }

    if (errorMessage.length > 0) {
      throw new FormError(errorMessage.join("<br>"), errorFields);
    }

    const res = await this.client.post(endpoints.postContactForm(), data);

    if (res.status === HttpStatusCode.OK) {
      return res.data as CreatedIdResponse;
    }

    throw new Error(formatResponseError(res));
  }
}

export const homeService = new HomeService();
