import { createApp, Component, defineAsyncComponent } from "vue";
import App from "./App.vue";
import vScroll from "@/directives/vScroll";
import router from "./router";
import store from "./store";

const app = createApp(App);

app.directive("intersect", vScroll);

type Components = { [key: string]: Promise<Component> };

const preloadComponents: Components = {
  icon: import("@/components/mols/icon/Icon.vue"),
  imageic: import("@/components/mols/image/Image.vue"),
  deferComponent: import(
    "@/components/mols/defer-component/DeferComponent.vue"
  ),
};

Object.keys(preloadComponents).forEach((key) => {
  const asyncComponent = defineAsyncComponent(() => preloadComponents[key]);
  app.component(key, asyncComponent);
});

app.use(store).use(router).mount("#app");
