import { WrapperMutationName, WrapperMutations } from "./types";

const mutations: WrapperMutations = {
  [WrapperMutationName.TOGGLE_LOCK_NAV](state, toggle: boolean) {
    state.lockNav = toggle;
  },

  [WrapperMutationName.TOGGLE_NAV](state, toggle: boolean) {
    state.toggledNav = toggle;
  },

  [WrapperMutationName.TOGGLE_LOCK_SCROLL](state, toggle: boolean) {
    state.lockScroll = toggle;
  },

  [WrapperMutationName.TOGGLE_COOKIES_CONSENT](state, toggle: boolean) {
    state.cookiesConsent = toggle;
  },

  [WrapperMutationName.TOGGLE_PAGE_LOADING](
    state,
    payload: {
      toggle: boolean;
      transparent: boolean;
    },
  ) {
    console.log("toggle", payload.toggle);
    if (!payload.toggle) {
      state.pageLoadingOut = true;
      setTimeout(() => {
        // state.lockNav = false
        state.lockScroll = false;
        state.pageLoading = payload;
      }, 1300);
    } else {
      state.toggledNav = false;
      // state.lockNav = true
      state.lockScroll = true;
      state.pageLoading = payload;
      state.pageLoadingOut = false;
    }
  },
};

export default mutations;
