import { ModalMutationName, ModalMutations } from "./types";
import { ModalContext } from "@/components/mols/modal/types";

const mutations: ModalMutations = {
  [ModalMutationName.TOGGLE](state, toggle: boolean) {
    if (!state.fired) {
      setTimeout(() => (state.toggle = toggle), 100);
      state.fired = true;
    } else {
      state.toggle = toggle;
    }
  },

  [ModalMutationName.SET_CONTEXT](state, context?: ModalContext) {
    state.context = context;
  },

  [ModalMutationName.UPDATE_DATA](state, data: unknown) {
    if (state.context) {
      state.context.data = data;
    }
  },
};

export default mutations;
