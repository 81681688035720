
export enum PaymentTypeEnum {
  TRANSFER = 'transfer'
}

export enum ProductDeliveryType {
  PERSONAL = 'personal',
  ELECTRONIC_PRODUCT = 'electronic_product',
}

export enum OrderStatus {
  WAITING_FOR_PAYMENT = 'waiting-for-payment',
  IN_PROGRESS = 'in-progress',
  SENT = 'sent',
  DELIVERED = 'delivered',
}

export type ProductDeliveryMethod = {
  type: ProductDeliveryType
  cost: number
}

export type ProductDeliveryMethodDetails = ProductDeliveryMethod & {
  name: string
  caution?: string
}

export type ProductPaymentMethodDetails = {
  type: PaymentTypeEnum
  name: string
  caution?: string
}

export type ProductCategory = {
  name: string
  slug: string
  description: string
  order: number
}

export type Product = {
  id: string
  name: string
  price: number
  order: number
  description: string
  deliveryTypes: Array<ProductDeliveryMethod>
  images: Array<{
    src: string
  }>
  category: ProductCategory
}

export type ProductList = {
  count: number
  page: number
  pages: number
  list: Array<{
    category: ProductCategory
    products: Product[]
  }>
}

export type Basket = {
  id: string
  paymentType?: PaymentTypeEnum
  products: Array<{
    product: Product
    quantity: number
  }>
}

export type BasketStore<T> = {
  paymentType?: PaymentTypeEnum
  products: Array<{
    product: T
    quantity: number
  }>
}

export type AddToBasketPayload = {
  productId: Product['id']
  basketId?: Basket['id']
  quantity?: number
}

export type CreateBasketPayload<T> = {
  products: Array<{
    product: T
    quantity: number
  }>
}

export type UpdateBasketPayload<T> = {
  paymentType?: PaymentTypeEnum
  products: Array<{
    product: T
    quantity: number
  }>
}

export type OrderContractorAddress = {
  name: string
  street: string
  streetNumber: string
  apartmentNumber: string
  postalCode: string
  city: string
  vatId?: string
}

export type OrderData = OrderContractorAddress & {
  deliveryMethod: ProductDeliveryType
  paymentMethod: PaymentTypeEnum
  invoiceType: 'recipe' | 'invoice'
  companyName: string
  vatId: string
  firstname: string
  lastname: string
  email: string
  rodoLegal: boolean
  rulesAccepted: boolean
  marketingConsent: boolean
}

export type OrderDataErrors = Partial<Record<keyof OrderData, string>>

export type OrderPayload = OrderData & {
  basketId: string
}

export type TransferMethodPaymentData = {
  transferAddress: string
  transferTitle: string
  transferBank: string
  transferIBan: string
}

export type PaymentMethodData = TransferMethodPaymentData

export type OrderConfirmation = {
  orderId: string
  orderToken: string
}

export type OrderDetails = {
  paymentMethod: PaymentTypeEnum
  paymentData: PaymentMethodData
  deliveryData: {
    componentName: string
    bind?: unknown
  }
  contractorAddress: OrderContractorAddress
  invoiceType: 'recipe' | 'invoice'
  createdAt: string
  status: OrderStatus
  products: Array<{
    product: Product
    quantity: number
  }>
}

export type CreateOrderPayload<T> = OrderPayload & {
  orderToken: string
  createdAt: string
  status: OrderStatus
  products: Array<{
    product: T
    quantity: number
  }>
}

export class OrderPayloadError extends Error {
  public orderPayloadError = true
  constructor(errors: OrderErrors) {
    super(JSON.stringify({
      ...errors,
      orderPayloadError: true,
    }));
  }
}

export type OrderErrors = Partial<Record<keyof OrderPayload, string>> & {
  orderPayloadError?: true
}
