import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vp-min vp-center bg-secondary clip-little-down z10 p-rel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_call_to_action_section = _resolveComponent("home-call-to-action-section")!
  const _component_home_closest_classes_section = _resolveComponent("home-closest-classes-section")!
  const _component_defer_component = _resolveComponent("defer-component")!
  const _component_home_seo_section = _resolveComponent("home-seo-section")!
  const _component_home_customer_reviews_section = _resolveComponent("home-customer-reviews-section")!
  const _component_home_adventages_section = _resolveComponent("home-adventages-section")!
  const _component_home_instagram_section = _resolveComponent("home-instagram-section")!
  const _component_home_find_us_section = _resolveComponent("home-find-us-section")!
  const _component_instant_call = _resolveComponent("instant-call")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_home_call_to_action_section)
    ]),
    _createVNode(_component_defer_component, { initClass: "vp-min vp-center bg-primary clip-little-down counter-clip-little-down z9" }, {
      default: _withCtx(() => [
        _createVNode(_component_home_closest_classes_section)
      ]),
      _: 1
    }),
    _createVNode(_component_defer_component, { initClass: "vp-min vp-center bg-white clip-little-down counter-clip-little-down z8" }, {
      default: _withCtx(() => [
        _createVNode(_component_home_seo_section)
      ]),
      _: 1
    }),
    _createVNode(_component_defer_component, { initClass: "vp-min vp-center bg-secondary clip-little-down counter-clip-little-down z7" }, {
      default: _withCtx(() => [
        _createVNode(_component_home_customer_reviews_section)
      ]),
      _: 1
    }),
    _createVNode(_component_defer_component, { initClass: "bg-primary clip-little-down counter-clip-little-down z6" }, {
      default: _withCtx(() => [
        _createVNode(_component_home_adventages_section)
      ]),
      _: 1
    }),
    _createVNode(_component_defer_component, { initClass: "vp-min vp-center clip-little-down counter-clip-little-down z5" }, {
      default: _withCtx(() => [
        _createVNode(_component_home_instagram_section)
      ]),
      _: 1
    }),
    _createVNode(_component_defer_component, null, {
      default: _withCtx(() => [
        _createVNode(_component_home_find_us_section)
      ]),
      _: 1
    }),
    (_ctx.instantCallVisible)
      ? (_openBlock(), _createBlock(_component_instant_call, {
          key: 0,
          hidden: _ctx.instantCallHidden
        }, null, 8, ["hidden"]))
      : _createCommentVNode("", true)
  ], 64))
}