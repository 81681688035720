import { createStore, StoreOptions } from "vuex";
import { AppState } from "./types";
import modal from "./modules/modal/index";
import wrapper from "./modules/wrapper/index";
import shop from "./modules/shop/index";

const store: StoreOptions<AppState> = {
  modules: {
    modal,
    wrapper,
    shop,
  },
};

export default createStore(store);
