import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "-error"
}
const _hoisted_3 = ["id", "value", "required", "name"]
const _hoisted_4 = {
  key: 0,
  class: "-required"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    for: _ctx.elementId,
    class: _normalizeClass([_ctx.error ? '--error' : '', "checkbox"])
  }, [
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, [
      _createElementVNode("input", {
        id: _ctx.elementId,
        value: _ctx.modelValue,
        required: _ctx.required,
        name: _ctx.name,
        type: "checkbox",
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.checked)))
      }, null, 40, _hoisted_3)
    ]),
    _createElementVNode("span", null, [
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "* "))
        : _createCommentVNode("", true),
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ])
  ], 10, _hoisted_1))
}