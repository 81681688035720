import {
  Module,
  ModuleActions,
  ModuleGetters,
  ModuleMutations,
} from "@/store/types";
import { ModalContext } from "@/components/mols/modal/types";

interface ModalState {
  fired: boolean;
  toggle: boolean;
  context?: ModalContext;
}

enum ModalMutationName {
  TOGGLE = "TOGGLE",
  SET_CONTEXT = "SET_CONTEXT",
  UPDATE_DATA = "UPDATE_DATA",
}

enum ModalActionName {
  OPEN_MODAL = "OPEN_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
}

type ModalGetters = ModuleGetters<ModalState>;
type ModalMutations = ModuleMutations<ModalState, ModalMutationName>;
type ModalActions = ModuleActions<ModalState, ModalActionName>;
type ModalModule = Module<ModalState>;

export {
  ModalState,
  ModalMutationName,
  ModalActionName,
  ModalGetters,
  ModalMutations,
  ModalActions,
  ModalModule,
};
