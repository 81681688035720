import { PageMeta } from "../../../typings/pages";

interface UseMeta {
  setTitle: (title: string) => void;
  setDescription: (description: string) => void;
  setCanonical: (url: string, absolute: boolean) => void;
  build: (title: string, description: string, url: string) => void;
  read: (meta: PageMeta) => void;
}

export default function useMeta(): UseMeta {
  const getBaseUrl = () => {
    return document.querySelector('head meta[property="baseurl"]') || "";
  };

  const setTitle = (title: string) => {
    document.title = title;
    document
      .querySelector('head meta[property="og:title"]')
      ?.setAttribute("content", title);
  };

  const setDescription = (description: string) => {
    document
      .querySelector('head meta[type="description"]')
      ?.setAttribute("content", description);
  };

  const setCanonical = (uri: string, absolute = false) => {
    const canonical = absolute ? uri : getBaseUrl() + uri;
    document
      .querySelector('head meta[property="og:url"]')
      ?.setAttribute("content", canonical);
    document
      .querySelector('head link[rel="canonical"]')
      ?.setAttribute("href", canonical);
  };

  const build = (title: string, description: string, url: string) => {
    setTitle(title);
    setDescription(description);
    setCanonical(url);
  };

  const read = (meta: PageMeta) => {
    setTitle(meta.title);
    setDescription(meta.description);
    setCanonical(meta.url);
  };

  return {
    setTitle,
    setDescription,
    setCanonical,
    build,
    read,
  };
}
