import { ShopMutationName, ShopMutations } from "./types";
import { Basket } from "#/shop";

const mutations: ShopMutations = {
  [ShopMutationName.UPDATE_BASKET](state, basket: Basket) {
    state.basket = basket;
  },
};

export default mutations;
