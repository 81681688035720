import {
  Module,
  ModuleActions,
  ModuleGetters,
  ModuleMutations,
} from "@/store/types";
import { Basket } from "#/shop";

interface ShopState {
  basket?: Basket;
}

enum ShopMutationName {
  UPDATE_BASKET = "UPDATE_BASKET",
}

enum ShopActionName {
  GET_BASKET = "GET_BASKET",
  ADD_TO_BASKET = "ADD_TO_BASKET",
  REMOVE_FROM_BASKET = "REMOVE_FROM_BASKET",
}

type ShopGetters = ModuleGetters<ShopState>;
type ShopMutations = ModuleMutations<ShopState, ShopMutationName>;
type ShopActions = ModuleActions<ShopState, ShopActionName>;
type ShopModule = Module<ShopState>;

export {
  ShopState,
  ShopMutationName,
  ShopActionName,
  ShopGetters,
  ShopMutations,
  ShopActions,
  ShopModule,
};
