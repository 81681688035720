<template>
  <div class="vp-min vp-center bg-secondary clip-little-down z10 p-rel">
    <home-call-to-action-section />
  </div>
  <defer-component
    initClass="vp-min vp-center bg-primary clip-little-down counter-clip-little-down z9"
  >
    <home-closest-classes-section />
  </defer-component>
  <defer-component
    initClass="vp-min vp-center bg-white clip-little-down counter-clip-little-down z8"
  >
    <home-seo-section />
  </defer-component>
  <defer-component
    initClass="vp-min vp-center bg-secondary clip-little-down counter-clip-little-down z7"
  >
    <home-customer-reviews-section />
  </defer-component>
  <defer-component
    initClass="bg-primary clip-little-down counter-clip-little-down z6"
  >
    <home-adventages-section />
  </defer-component>
  <defer-component
    initClass="vp-min vp-center clip-little-down counter-clip-little-down z5"
  >
    <home-instagram-section />
  </defer-component>
  <defer-component>
    <home-find-us-section />
  </defer-component>
  <instant-call v-if="instantCallVisible" :hidden="instantCallHidden" />
</template>

<script lang="ts">
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onMounted,
  ref,
} from "vue";
import HomeCallToActionSection from "@/components/orgs/home/HomeCallToActionSection.vue";
import { WrapperMutationName } from "@/store/modules/wrapper/types";
import { useStore } from "vuex";
import useMeta from "@/composables/use-meta";

export default defineComponent({
  name: "HomeComponent",

  components: {
    HomeCallToActionSection,
    HomeClosestClassesSection: defineAsyncComponent(
      () => import("@/components/orgs/home/HomeClosestClassesSection.vue"),
    ),
    HomeSeoSection: defineAsyncComponent(
      () => import("@/components/orgs/home/HomeSeoSection.vue"),
    ),
    HomeCustomerReviewsSection: defineAsyncComponent(
      () => import("@/components/orgs/home/HomeCustomerReviewsSection.vue"),
    ),
    HomeAdventagesSection: defineAsyncComponent(
      () => import("@/components/orgs/home/HomeAdventagesSection.vue"),
    ),
    HomeInstagramSection: defineAsyncComponent(
      () => import("@/components/orgs/home/HomeInstagramSection.vue"),
    ),
    HomeFindUsSection: defineAsyncComponent(
      () => import("@/components/orgs/home/HomeFindUsSection.vue"),
    ),
    InstantCall: defineAsyncComponent(
      () => import("@/components/mols/instant-call/InstantCall.vue"),
    ),
  },

  props: {
    pageScrollY: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const store = useStore();
    const meta = useMeta();

    const instantCallVisible = ref(false);
    const instantCallHidden = computed(
      () =>
        instantCallVisible.value &&
        props.pageScrollY >
          document.body.scrollHeight - window.outerHeight - 500,
    );

    onMounted(() => {
      meta.build(
        "FULL OUT Anna Wojcieszek | annawojcieszek.pl",
        "Miejsce w którym Taniec nowoczesny spotyka się ze świadomą pracą ciała Pilates. Zarezerwuj mniejsce i spróbuj!",
        "",
      );
      if (window.outerWidth < 768) {
        instantCallVisible.value = true;
      }

      store.commit(WrapperMutationName.TOGGLE_PAGE_LOADING, {
        toggle: false,
      });
    });

    return {
      instantCallVisible,
      instantCallHidden,
    };
  },
});
</script>
