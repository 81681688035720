import { ModalActionName, ModalActions, ModalMutationName } from "./types";
import { ModalContext } from "@/components/mols/modal/types";
import { WrapperMutationName } from "@/store/modules/wrapper/types";

const actions: ModalActions = {
  [ModalActionName.OPEN_MODAL]({ commit }, context: ModalContext) {
    commit(ModalMutationName.SET_CONTEXT, context);
    commit(ModalMutationName.TOGGLE, true);
    commit(WrapperMutationName.TOGGLE_LOCK_NAV, true);
  },

  [ModalActionName.CLOSE_MODAL]({ commit }) {
    commit(ModalMutationName.TOGGLE, false);
    commit(WrapperMutationName.TOGGLE_LOCK_NAV, false);
    setTimeout(() => commit(ModalMutationName.SET_CONTEXT, undefined), 500);
  },
};

export default actions;
