import { DirectiveBinding } from "vue";

export type IntersectEntry = {
  intersect?: boolean;
  loaded?: boolean;
};

const animatedScrollObserver = new IntersectionObserver(
  (entries, animatedScrollObserver) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target as CallbackEl;
        target.callback(target, target.intersectReference);
        animatedScrollObserver.unobserve(entry.target);
      }
    });
  },
  {
    rootMargin: `0px 0px ${window.outerHeight}px 0px`,
  },
);

export interface CallbackEl extends Element {
  callback: (el: Element, intersectReference?: IntersectEntry) => void;
  intersectReference?: IntersectEntry;
}

const vScroll = {
  beforeMount(el: CallbackEl, binding: DirectiveBinding): void {
    el.callback = binding.value.onChange;
    el.intersectReference = binding.value.ref;
    animatedScrollObserver.observe(el);
  },
};

export default vScroll;
