<template>
  <form action="" class="leave-phone-confirmation">
    <p class="p2 t-white">
      Jeszcze tylko jedna zgoda, abym mogła skontaktować się z Tobą:<br />
      Nr tel.: <strong>{{ data.phone }}</strong
      ><br />
      <span v-if="data.preferredHour"
        >Preferowana godzina: <strong>{{ data.preferredHour }}</strong></span
      >
    </p>
    <checkbox
      :modelValue="data.value"
      :error="error"
      :required="true"
      name="rodoLegal"
      label="Wyrażam zgodę na przetwarzanie moich danych osobowych przez PROJEKT-A Anna Wojcieszek w celu realizacji zamówionej przeze mnie prośby o kontakt telefoniczny."
      @update:modelValue="updateData"
    />
  </form>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Checkbox from "@/components/mols/input/Checkbox.vue";
import { LeavePhoneConfirmationContext } from "@/components/mols/leave-phone-confirmation/types";

export default defineComponent({
  name: "LeavePhoneConfirmationComponent",

  components: {
    Checkbox,
  },

  emits: ["update:data"],

  props: {
    data: {
      type: Object as () => LeavePhoneConfirmationContext,
      required: true,
    },
  },

  setup(props, { emit }) {
    const error = ref<string | undefined>(undefined);

    const updateData = () => {
      emit("update:data", {
        ...props.data,
        value: !props.data.value,
      });
    };

    return {
      error,
      updateData,
    };
  },
});
</script>

<style lang="scss">
.leave-phone-confirmation {
  .checkbox {
    background-color: rgba(var(--c-primary), 0.2);
    color: white;
  }
}
</style>
