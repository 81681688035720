import {
  Module,
  // ModuleActions,
  ModuleGetters,
  ModuleMutations,
} from "@/store/types";

interface WrapperState {
  lockNav: boolean;
  lockScroll: boolean;
  toggledNav: boolean;
  pageLoading: {
    toggle: boolean;
    transparent?: boolean;
  };
  pageLoadingOut: boolean;
  cookiesConsent: boolean;
}

enum WrapperMutationName {
  TOGGLE_LOCK_NAV = "TOGGLE_LOCK_NAV",
  TOGGLE_LOCK_SCROLL = "TOGGLE_LOCK_SCROLL",
  TOGGLE_PAGE_LOADING = "TOGGLE_PAGE_LOADING",
  TOGGLE_NAV = "TOGGLE_NAV",
  TOGGLE_COOKIES_CONSENT = "TOGGLE_COOKIES_CONSENT",
}

// enum WrapperActionName {
//   OPEN_Wrapper = 'OPEN_Wrapper',
//   CLOSE_Wrapper = 'CLOSE_Wrapper',
// }

type WrapperGetters = ModuleGetters<WrapperState>;
type WrapperMutations = ModuleMutations<WrapperState, WrapperMutationName>;
// type WrapperActions = ModuleActions<WrapperState, WrapperActionName>
type WrapperModule = Module<WrapperState>;

export {
  WrapperState,
  WrapperMutationName,
  // WrapperActionName,
  WrapperGetters,
  WrapperMutations,
  // WrapperActions,
  WrapperModule,
};
