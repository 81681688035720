import { Endpoints } from "@/api/types";
import { ShopServiceType } from "./types";

const API_SHOP_URL = "/api/shop";

const endpoints: Endpoints<ShopServiceType> = {
  getProductsList: () => `${API_SHOP_URL}/list`,
  addToBasket: () => `${API_SHOP_URL}/basket/add`,
  removeFromBasket: () => `${API_SHOP_URL}/basket/remove`,
  getBasket: (basketId: string) => `${API_SHOP_URL}/basket/get/${basketId}`,
  makeOrder: () => `${API_SHOP_URL}/basket/make-order`,
  getOrder: (orderId: string, orderToken: string) =>
    `${API_SHOP_URL}/basket/get-order/${orderId}/${orderToken}`,
};

export default endpoints;
