import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/sklep",
    name: "Shop",
    component: () =>
      import(/* webpackChunkName: "staff" */ "../views/Shop.vue"),
  },
  {
    path: "/koszyk",
    name: "Basket",
    component: () =>
      import(/* webpackChunkName: "basket" */ "../views/Basket.vue"),
  },
  {
    path: "/zamowienie/:id/:token",
    name: "Order",
    component: () =>
      import(/* webpackChunkName: "basket" */ "../views/Order.vue"),
  },
  {
    path: "/grafik",
    name: "Classes",
    component: () =>
      import(/* webpackChunkName: "classes" */ "../views/Classes.vue"),
  },
  {
    path: "/kontakt",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/:page",
    name: "Page",
    component: () =>
      import(/* webpackChunkName: "donation" */ "../views/Page.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
