import { Return } from "@/api/types";
import { CustomerReviewResponse } from "#/customer-reviews";
import { ClassResponse } from "#/classes";
import {
  ContactFormPayload,
  LeavePhonePayload,
  CreatedIdResponse,
} from "#/leave-phone";
import { Pages } from "#/pages";

export type HomeServiceType = {
  getClasses: () => Return<ClassResponse>;
  getPage: (page: string) => Return<Pages>;
  getClosestClasses: () => Return<ClassResponse>;
  getCustomerReviews: () => Return<CustomerReviewResponse>;
  getHomeInstagramPost: () => Return<{ permalink: string }>;
  postLeavePhone: (data: LeavePhonePayload) => Return<CreatedIdResponse>;
  postContactForm: (data: ContactFormPayload) => Return<CreatedIdResponse>;
};

export type FieldErrors<T> = Partial<Record<keyof T, boolean>>;

export class FormError extends Error {
  public fields: FieldErrors<ContactFormPayload>;
  constructor(message: string, fields: FieldErrors<ContactFormPayload>) {
    super(message);
    this.fields = fields;
  }
}
