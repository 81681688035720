import { ShopActions, ShopActionName, ShopMutationName } from "./types";
import { Basket, Product } from "#/shop";
import { shopService } from "@/api/service/shop";

const actions: ShopActions = {
  async [ShopActionName.GET_BASKET]({ commit, state }, basketId: Basket["id"]) {
    try {
      const res = await shopService.getBasket(basketId);
      commit(ShopMutationName.UPDATE_BASKET, res);
      console.log(state.basket);
    } catch (e) {
      console.log("removing basket");
      document.cookie = "basketId=;Max-Age=-99999999;";
    }
  },

  async [ShopActionName.ADD_TO_BASKET](
    { state, dispatch },
    {
      product,
      quantity,
    }: {
      product: Product;
      quantity: number;
    },
  ) {
    try {
      const res = await shopService.addToBasket(
        product.id,
        state.basket?.id,
        quantity,
      );
      if (!state.basket) {
        document.cookie = `basketId=${res.id}`;
      }
      await dispatch(ShopActionName.GET_BASKET, res.id);
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  async [ShopActionName.REMOVE_FROM_BASKET](
    { commit, state, dispatch },
    productId: Product["id"],
  ) {
    try {
      if (!state.basket) {
        return;
      }
      const res = await shopService.removeFromBasket(
        productId,
        state.basket.id,
      );

      if (res.id === null) {
        commit(ShopMutationName.UPDATE_BASKET, undefined);
        document.cookie = "basketId=;Max-Age=-99999999;";
      } else {
        await dispatch(ShopActionName.GET_BASKET, state.basket.id);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};

export default actions;
