<template>
  <section class="cta-section">
    <div class="-text t-white t-center clip-both">
      <h2>ZAPISZ SIĘ NA ZAJĘCIA!</h2>
      <p>
        Zostaw swój numer oraz preferowaną godzinę kontaktu. Zadzwonię do
        Ciebie.
      </p>
    </div>
    <div class="-form clip-both">
      <form action="" class="flex" @submit.prevent="askConsent">
        <label for="cta-section-phone" class="-phone">
          <input
            v-model="formData.phone"
            :class="phoneError ? '--error' : ''"
            id="cta-section-phone"
            type="text"
            name="phone"
            class="-phone-input"
            placeholder="+48"
            @paste="onPhoneInput($event, true)"
            @input="onPhoneInput"
          />
        </label>
        <label for="cta-section-preferred-hour" class="-preferred-hour">
          <input
            v-model="formData.preferredHour"
            id="cta-section-preferred-hour"
            :class="timeFocused ? '--focused' : ''"
            type="time"
            class="-preferred-hour-input"
            @focus="setTimeFocued"
          />
        </label>
        <label class="-submit">
          <button type="submit" class="btn bg-primary">
            <span>wyślij</span>
            <icon
              :icon="isSending ? iconSet.dotsHorizontal : iconSet.send"
              :size="30"
              fill="#fff"
            />
          </button>
        </label>
      </form>
    </div>
  </section>
  <div class="paperwall">
    <imageic src="ania-1-m.jpg" src-desktop="ania-1-d.jpg" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { iconSet } from "@/utils/iconset";
import { LeavePhoneFormData, LeavePhonePayload } from "#/leave-phone";
import { homeService } from "@/api/service/home";
import { useStore } from "vuex";
import { ModalActionName } from "@/store/modules/modal/types";
import { ModalContext, ModalMood } from "@/components/mols/modal/types";
import LeavePhoneConfirmation from "@/components/mols/leave-phone-confirmation/LeavePhoneConfirmation.vue";
import { LeavePhoneConfirmationContext } from "@/components/mols/leave-phone-confirmation/types";
import { WrapperMutationName } from "@/store/modules/wrapper/types";

const autofillBgColor = "rgb(232, 240, 254)";

export default defineComponent({
  name: "HomeCallToActionSection",

  setup() {
    const store = useStore();

    const isSending = ref(false);
    const phoneError = ref(false);
    const timeFocused = ref(false);

    const formData = reactive<LeavePhoneFormData>({
      phone: null,
      preferredHour: null,
    });

    const clearFormData = () => {
      formData.phone = null;
      formData.preferredHour = null;
    };

    const onPhoneInput = (event: InputEvent, isPasted = false) => {
      const computedBg = window.getComputedStyle(
        event.target as HTMLInputElement,
      ).backgroundColor;

      if (formData.phone === null) return;

      phoneError.value = false;

      if (computedBg === autofillBgColor || isPasted) {
        formData.phone = formData.phone.replace(" ", "");

        if (formData.phone.length > 9) {
          formData.phone = formData.phone.slice(-9);
        }

        if (isNaN(+formData.phone)) formData.phone = null;
      } else if (formData.phone.length > 9) {
        formData.phone = formData.phone.slice(0, 9);
      } else if (isNaN(+formData.phone)) {
        formData.phone = formData.phone.slice(0, formData.phone.length - 1);
      }
    };

    const askConsent = async () => {
      if (!formData.phone || formData.phone.length !== 9) {
        phoneError.value = true;
        return;
      }

      isSending.value = true;

      await store.dispatch(ModalActionName.OPEN_MODAL, {
        submitButton: {
          callback: async () => {
            await onSubmit();
          },
          disabled: (data: LeavePhoneConfirmationContext) => {
            return !data.value;
          },
          text: "Wyślij",
        },
        title: "Już prawie...",
        component: LeavePhoneConfirmation,
        data: {
          value: false,
          ...formData,
        },
        mood: ModalMood.MOODLESS,
      } as ModalContext<LeavePhoneConfirmationContext>);
    };

    const onSubmit = async () => {
      try {
        store.commit(WrapperMutationName.TOGGLE_PAGE_LOADING, {
          toggle: true,
        });

        const res = await homeService.postLeavePhone({
          ...formData,
          consent: true,
        } as LeavePhonePayload);

        await store.dispatch(ModalActionName.OPEN_MODAL, {
          submitButton: {
            callback: () => {
              store.dispatch(ModalActionName.CLOSE_MODAL);
            },
            text: "Ok, dziękuję",
          },
          title: "Gotowe!",
          content: `Dziękuję! Niebawem się z Tobą skontaktuję. Identyfikator Twojego zgłoszenia to:<br><strong>${res.id}</strong>`,
          mood: ModalMood.SUCCESS,
        } as ModalContext);

        store.commit(WrapperMutationName.TOGGLE_PAGE_LOADING, {
          toggle: false,
        });

        clearFormData();
      } catch (e) {
        await store.dispatch(ModalActionName.OPEN_MODAL, {
          cancelButton: true,
          title: "Upsss...",
          content: "Wystąpił problem z wysłaniem zgłoszenia. Spróbuj później.",
          mood: ModalMood.ERROR,
        } as ModalContext);
      } finally {
        isSending.value = false;
      }
    };

    const setTimeFocued = () => {
      timeFocused.value = true;
    };

    onMounted(() => {
      clearFormData();
    });

    return {
      iconSet,
      isSending,
      phoneError,
      timeFocused,
      formData,
      onPhoneInput,
      askConsent,
      setTimeFocued,
    };
  },
});
</script>

<style lang="scss">
.cta-section {
  position: relative;
  z-index: 10;
  width: 100%;
  margin-top: 20vh;

  .-text {
    padding: 30px 0;
    background-color: rgba(var(--c-secondary), 0.9);

    p {
      padding: 0 20px;
      font-size: 1.25rem;
      line-height: 1.5;
    }
  }

  @media (min-width: 1280px) and (orientation: landscape) {
    .-text {
      h2 {
        font-size: 2.5rem;
      }
      p {
        font-size: 1.5rem;
      }
    }
  }

  .-form {
    margin-top: -25px;
    padding: 30px 0;
    background-color: rgba(var(--c-secondary), 0.4);
  }

  form {
    margin: 0 auto;
    max-width: 320px;
    font-size: 0;

    label {
      font-size: 0;
    }

    .-phone {
      flex: 0 0 100%;
      margin-bottom: 2px;
    }

    .-phone-input {
      text-align: center;
      font-size: 2rem;
      letter-spacing: 10px;
      margin: 0;
      padding: 10px;
      width: 100%;
      border-radius: 10px;
      color: rgb(var(--c-secondary));

      &::placeholder {
        text-align: left;
        color: rgba(var(--c-secondary), 0.7);
        font-size: 1.5rem;
      }

      &.--error {
        background-color: red;
        color: white;
      }

      &:-webkit-autofill {
        background-color: #eee;
      }
    }

    .-preferred-hour {
      flex: 0 0 50%;
      margin: 5px 5px 0 0;
      flex-basis: calc(50% - 5px);
    }

    .-preferred-hour-input {
      position: relative;
      width: 100%;
      padding: 0 10px;
      height: 100%;
      font-size: 1.5rem;
      min-height: 50px;
      border-radius: 10px;
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      @media not all and (min-resolution: 0.001dpcm) {
        &:not(.--focused) {
          &:after {
            width: 100%;
            display: block;
            position: absolute;
            text-align: center;
            top: 10px;
            left: 0px;
            content: "godzina";
          }
        }
      }
    }

    .-submit {
      display: inline-block;
      padding: 0;
      margin: 5px 0 0 5px;
      flex: 0 0 50%;
      flex-basis: calc(50% - 5px);

      button {
        width: 100%;
      }
    }

    .message {
      font-size: 1rem;
      margin-bottom: 10px;
      padding: 10px;
      color: white;
      text-align: center;
      background-color: rgb(var(--c-primary));
      border-radius: 10px;

      strong {
        letter-spacing: 2px;
      }

      &.-success {
        background-color: green;
      }
    }
  }
}
</style>
