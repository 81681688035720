import axiosClient from "./client";
import { AxiosResponse } from "axios";

type ResponseError = {
  errors: string[];
};

export const formatResponseError = <T extends AxiosResponse>(
  response: T,
): string => {
  const error: ResponseError = response?.data as ResponseError;
  return error.errors ? error.errors.join(",") : response.statusText;
};

export default class Service {
  constructor(protected client = axiosClient) {}
}
