import { Component } from "vue";

export enum ModalMood {
  MOODLESS = "moodless",
  SUCCESS = "success",
  ERROR = "error",
}

export type ModalSubmitCallback<T> = (data: T) => void;

export type ModalDisabledCallback<T> = (data: T) => boolean;

export type ModalContext<T = unknown> = Partial<{
  title: string;
  component: string | Component;
  content: string;
  mood: ModalMood;
  cancelButton: boolean;
  submitButton: {
    text: string;
    callback: ModalSubmitCallback<T>;
    disabled?: ModalDisabledCallback<T>;
  };
  data: T;
}>;
