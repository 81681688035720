<template>
  <div
    style="
      --c-primary: 69, 194, 197;
      --c-secondary: 35, 61, 148;
      --c-action: 240, 204, 7;
      --c-error: 180, 11, 11;
      --c-success: 63, 154, 13;
    "
  >
    <header
      :class="{
        '--hidden': pageScrollDir === 'down' || navIsLocked,
        '--out-of-top': pageScrollY > 100,
      }"
      class="page-header"
    >
      <div
        :class="{
          '--with-basket': basket && basket.products.length > 0,
        }"
        class="-wrapper p1"
      >
        <router-link to="/" class="f-reset">
          <img
            :src="headerLogoSrc"
            alt="AnnaWojcieszek.pl | Instruktor Fitness"
            class="-logo"
          />
        </router-link>
        <div class="vp-center">
          <router-link
            v-if="basket && basket.products.length > 0"
            aria-label="Basket"
            class="-basket"
            to="/koszyk"
          >
            <icon :icon="iconSet.basket" :size="40" fill="#fff" />
            <div class="-basket-ball bg-primary vp-center">
              <span>{{ basket.products.length }}</span>
            </div>
          </router-link>
          <button
            aria-label="Menu"
            class="-nav-toggle"
            @click="toggleNav(!isNavToggled)"
          >
            <icon :icon="iconSet.menu" :size="40" fill="#fff" />
          </button>
        </div>
      </div>
      <nav id="nav" :class="isNavToggled ? '--toggle' : ''" class="p1">
        <ul id="menu">
          <li>
            <router-link to="/" @click="scrollTop">Home</router-link>
          </li>
          <li>
            <router-link to="/o-mnie" @click="scrollTop">O mnie</router-link>
          </li>
          <li>
            <router-link to="/studio" @click="scrollTop">Studio</router-link>
          </li>
          <li>
            <router-link to="/grafik" @click="scrollTop">Grafik</router-link>
          </li>
          <li>
            <router-link to="/sklep" @click="scrollTop">Sklep</router-link>
          </li>
          <li>
            <router-link to="/kontakt" @click="scrollTop">Kontakt</router-link>
          </li>
        </ul>
      </nav>
    </header>
    <main>
      <router-view :pageScrollY="pageScrollY" />
    </main>
    <footer class="t-center">
      <section class="-fast-contact bg-primary clip-little-down">
        <h2>Szybki kontakt</h2>
        <ul class="-contact-list vp-center">
          <li>
            <button type="button" @click="callPhone">
              <icon :icon="iconSet.phone" :size="50" fill="white" />
            </button>
          </li>
          <li>
            <button type="button" @click="openMessenger">
              <icon :icon="iconSet.messenger" :size="50" fill="white" />
            </button>
          </li>
          <li>
            <button type="button" @click="sendEmail">
              <icon :icon="iconSet.email" :size="50" fill="white" />
            </button>
          </li>
          <li>
            <button type="button" @click="callFaceTime">
              <icon :icon="iconSet.faceTime" :size="50" fill="white" />
            </button>
          </li>
        </ul>
      </section>
      <div class="bg-secondary clip-little-down counter-clip-little-down">
        <ul id="footer-menu" class="-nav container p1">
          <li>
            <router-link to="/" @click="scrollTop">Home</router-link>
          </li>
          <li>
            <router-link to="/o-mnie" @click="scrollTop">O mnie</router-link>
          </li>
          <li>
            <router-link to="/studio" @click="scrollTop">Studio</router-link>
          </li>
          <li>
            <router-link to="/kadra" @click="scrollTop">Kadra</router-link>
          </li>
          <li>
            <router-link to="/oferta" @click="scrollTop">Oferta</router-link>
          </li>
          <li>
            <router-link to="/grafik" @click="scrollTop">Grafik</router-link>
          </li>
          <li>
            <router-link to="/sklep" @click="scrollTop">Sklep</router-link>
          </li>
          <li>
            <router-link to="/kontakt" @click="scrollTop">Kontakt</router-link>
          </li>
        </ul>
      </div>
      <div class="-eu flex counter-clip-little-down">
        <router-link
          class="container t-center"
          to="/dotacja"
          @click="scrollTop"
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/annawojcieszekpl.appspot.com/o/eu-mobile.jpg?alt=media"
            alt="Fundusze Europejskie"
          />
        </router-link>
        <p class="container t-center">
          Europejski Fundusz Rolny na rzecz Rozwoju Obszarów Wiejskich: Europa
          inwestująca w obszary wiejskie
        </p>
      </div>
      <div class="footer-copyright bg-secondary t-white">
        <p class="-copy p1">
          Copyright {{ new Date().getFullYear() }} &copy; annawojcieszek.pl
        </p>
        <p class="-disclaimer">
          Wszystkie zamieszczone materiały znajdujące się na stronie
          annawojcieszek.pl objęte są prawami autorskimi.<br />
          Wykorzystanie ich bez zgody właściciela witryny jest surowo
          zabroniony.
        </p>
      </div>
    </footer>
    <modal v-if="modalFired" />
    <div
      v-if="pageLoading.toggle"
      :class="{
        '--out': pageLoadingOut,
        '--transparent': pageLoading.transparent,
      }"
      class="page-loader vp-min vp-center bg-secondary"
    >
      <div class="-loader">
        <div :class="{ '--transparent': pageLoading.transparent }"></div>
        <div :class="{ '--transparent': pageLoading.transparent }"></div>
        <div :class="{ '--transparent': pageLoading.transparent }"></div>
        <div :class="{ '--transparent': pageLoading.transparent }"></div>
      </div>
    </div>
    <cookies-consent v-if="consentVisible" />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
import { iconSet } from "@/utils/iconset";
import { getCdnImageUrl } from "@/utils/images";
import { useStore } from "vuex";
import { getCallUrl, getEmail } from "@/utils/get-call-url";
import { useRouter } from "vue-router";
import { WrapperMutationName } from "@/store/modules/wrapper/types";
import { scrollTop } from "@/utils/scroll-top";
import { getCookie } from "@/utils/cookies";
import { ShopActionName } from "@/store/modules/shop/types";
import { Basket } from "#/shop";

enum PageScrollDir {
  UP = "up",
  DOWN = "down",
}

export default defineComponent({
  name: "App",

  components: {
    Modal: defineAsyncComponent(
      () => import("@/components/mols/modal/Modal.vue"),
    ),
    CookiesConsent: defineAsyncComponent(
      () => import("@/components/orgs/cookies-consent/CookiesConsent.vue"),
    ),
  },

  setup() {
    const store = useStore();
    const { currentRoute } = useRouter();

    const headerLogoSrc = ref(getCdnImageUrl("logo-fullout-w.png"));
    const pageScrollY = ref(0);
    const pageScrollDir = ref(PageScrollDir.UP);

    const modalFired = computed<boolean>(() => store.getters.getIsModalFired);

    const navIsLocked = computed<boolean>(() => store.getters.getIsNavLocked);

    const pageLoading = computed<{
      toggle: boolean;
      transparent?: boolean;
    }>(() => store.getters.getIsPageLoading);

    const pageLoadingOut = computed<boolean>(
      () => store.getters.getIsPageLoadingOut,
    );

    const isNavToggled = computed<boolean>(() => store.getters.getIsNavToggled);

    const basket = computed<Basket>(() => store.getters.getBasket);

    const consentVisible = computed(
      () => store.getters.getIsCookiesConsentVisible,
    );

    const toggleNav = (toggle: boolean) => {
      store.commit(WrapperMutationName.TOGGLE_NAV, toggle);
    };

    const callFaceTime = () => {
      window.open(getCallUrl("facetime://"), "_self");
    };

    const callPhone = () => {
      window.open(getCallUrl(), "_self");
    };

    const openMessenger = () => {
      window.open("https://m.me/FullOutAnnaWojcieszek", "_blank");
    };

    const sendEmail = () => {
      window.open("mailto:" + getEmail(), "_self");
    };

    watch(
      () => currentRoute.value,
      () =>
        store.commit(WrapperMutationName.TOGGLE_PAGE_LOADING, {
          toggle: true,
        }),
    );

    onBeforeMount(() => {
      const consent = getCookie("cookiesConsent");

      if (!consent || consent === "0") {
        store.commit(WrapperMutationName.TOGGLE_COOKIES_CONSENT, true);
      }
    });

    onMounted(async () => {
      window.addEventListener("scroll", () => {
        pageScrollDir.value =
          pageScrollY.value < window.scrollY && window.scrollY > 100
            ? PageScrollDir.DOWN
            : PageScrollDir.UP;
        pageScrollY.value = window.scrollY;
      });

      const fontStatus = await document.fonts.ready;
      if (!fontStatus || (fontStatus && fontStatus.status === "loaded")) {
        store.commit(WrapperMutationName.TOGGLE_PAGE_LOADING, {
          toggle: false,
        });
        document.body.style.overflowY = "initial";
      }

      store.subscribe((mutation) => {
        if (mutation.type === WrapperMutationName.TOGGLE_LOCK_SCROLL) {
          document.body.style.overflowY =
            mutation.payload === true ? "hidden" : "initial";
        }
      });

      const basketId = getCookie("basketId");
      if (basketId) {
        await store.dispatch(ShopActionName.GET_BASKET, basketId);
      }
    });

    return {
      iconSet,
      headerLogoSrc,
      isNavToggled,
      pageLoading,
      pageLoadingOut,
      pageScrollY,
      pageScrollDir,
      modalFired,
      navIsLocked,
      basket,
      consentVisible,
      callFaceTime,
      callPhone,
      openMessenger,
      sendEmail,
      toggleNav,
      scrollTop,
    };
  },
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;500&display=swap");
@import "./scss/reset.scss";
@import "./scss/loader.scss";

* {
  box-sizing: border-box;
}

body {
  font-weight: 300;
}

h1,
h2,
h3 {
  font-weight: 500;
  text-transform: uppercase;
}

.flex {
  display: flex;
  flex-flow: row wrap;
}

.vp-min {
  min-height: 100vh;

  &.clip-little-down {
    min-height: calc(100vh + 30px);
  }
}

.vp-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.p1 {
  padding: 10px;
}

.p2 {
  padding: 20px;
}

.m1 {
  margin: 10px;
}

.btn {
  transition: background 400ms;
  position: relative;
  overflow: hidden;
  outline: 0;
  border: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: white;
  background-color: rgb(var(--c-action));
  height: 100%;
  padding: 10px 15px;
  margin: 0;
  font-size: 1.25rem;
  text-decoration: none;
  cursor: pointer;

  &:disabled {
    background-color: grey;
  }

  svg {
    margin-left: 10px;
  }

  span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.container {
  max-width: 800px !important;
  width: 100% !important;
  margin: 0 auto;
}

.bg-primary {
  background-color: rgb(var(--c-primary));
}

.bg-secondary {
  background-color: rgb(var(--c-secondary));
}

.bg-action {
  background-color: rgb(var(--c-action));
}

.bg-white {
  background-color: white;
}

.bg-grey {
  background-color: grey;
}

.t-center {
  text-align: center;
}

.t-white {
  color: white;
}

.t-secondary {
  color: rgb(var(--c-secondary));
}

.p-rel {
  position: relative;
}

@for $i from 1 through 10 {
  .z#{$i} {
    z-index: #{$i};
  }
}

.page-header {
  position: fixed;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  height: 100px;
  z-index: 100;
  transition: all 200ms ease-in-out;

  &.--out-of-top {
    background: rgb(var(--c-secondary));
    height: 80px;

    .-logo {
      height: 60px;
    }

    .-basket {
      @media (min-width: 768px) {
        top: 20px;
      }
    }
  }

  &.--hidden {
    transform: translateY(-200px);
  }

  @media (min-width: 768px) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  .-logo {
    transition: height 200ms ease-in-out;
    height: 80px;
  }

  .-wrapper {
    position: relative;
    z-index: 101;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 768px) {
      max-width: 100px;
    }

    &.--with-basket + nav {
      @media (min-width: 768px) {
        margin-right: 80px;
      }
    }
  }

  .-basket {
    position: relative;
    padding-right: 20px;

    @media (min-width: 768px) {
      position: fixed;
      top: 30px;
      right: 10px;
      max-width: 100px;
    }
  }

  .-basket-ball {
    position: absolute;
    top: 0px;
    right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    span {
      font-size: 0.75rem;
      color: white;
      font-weight: 500;
    }
  }

  .-nav-toggle {
    width: 60px;
    height: 60px;
    background-color: transparent;

    @media (min-width: 768px) {
      display: none;
    }
  }

  nav {
    z-index: 100;
    @media (max-width: 767px) {
      position: fixed;
      top: 0;
      width: 100vw;
      transform: translateX(100vw);
      transition: transform 200ms ease-in-out;
      min-height: calc(100vh);
      background-color: rgb(var(--c-secondary));
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    @media (min-width: 768px) {
      margin-right: 0;
      transition: margin-right 200ms ease-in-out;
      ul li {
        padding: 0 15px;
        display: inline-block;

        a {
          font-size: 1.5rem;
        }
      }
    }

    &.--toggle {
      transform: translateX(0vw);
    }

    a {
      font-size: 2rem;
      line-height: 2;
      color: white;
    }
  }
}

.section-wrapper {
  position: relative;
}

section {
  position: relative;
  z-index: 2;

  h2 {
    color: white;
    font-size: 2rem;
    text-transform: uppercase;
  }
}

.paperwall {
  max-width: 100vw;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}

footer {
  .-fast-contact {
    padding: 20px 0 40px;
  }

  .-contact-list {
    padding-top: 20px;

    li {
    }
  }

  .-nav {
    padding: 40px 10px;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

    a {
      font-size: 1.25rem;
      line-height: 2;
      color: white;
    }
  }

  .-disclaimer {
    padding-bottom: 20px;
    font-size: 0.6rem;
    opacity: 0.7;
    line-height: 1.5;
  }

  .-eu {
    padding-top: 45px;
    width: 100%;

    a {
      display: block;
      padding: 10px;
    }

    img {
      max-width: 100%;
      max-height: 100px;
    }

    p {
      padding: 10px;
    }
  }
}

.clip-both {
  clip-path: polygon(0% 0%, 50% 15%, 100% 0%, 100% 85%, 50% 100%, 0% 85%);
}

.clip-down {
  clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0% 85%);
}

.clip-little-down {
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% calc(100% - 30px),
    50% 100%,
    0% calc(100% - 30px)
  );
}

.counter-clip-little-down {
  position: relative;
  margin-top: -30px;
}

.shadow {
  -webkit-box-shadow: 0px 0px 40px -10px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 40px -10px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 40px -10px rgba(66, 68, 90, 1);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
</style>
