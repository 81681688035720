import state from "./state";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import { ModalModule } from "./types";

const wrapper: ModalModule = {
  state,
  getters,
  mutations,
  actions,
};

export default wrapper;
