import { Endpoints } from "@/api/types";
import { HomeServiceType } from "./types";

const API_URL = "/api";

const endpoints: Endpoints<HomeServiceType> = {
  getClasses: () => `${API_URL}/classes`,
  getPage: (page: string) => `${API_URL}/page/${page}`,
  getClosestClasses: () => `${API_URL}/classes/closest`,
  getCustomerReviews: () => `${API_URL}/customer-reviews`,
  getHomeInstagramPost: () => `${API_URL}/home-instagram-post`,
  postLeavePhone: () => `${API_URL}/leave-phone`,
  postContactForm: () => `${API_URL}/contact-form`,
};

export default endpoints;
